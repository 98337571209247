import {
  FunctionField,
  Link,
  ListProps,
  ReferenceField,
  TextField,
} from 'react-admin';
import React from 'react';

import {
  BooleanField,
  EmailField,
  Grid,
  GridList,
  ManyFilter,
  MoneyField,
  PhoneField,
  ReferenceInput,
  SelectInput,
  HistoryField,
  NullableBooleanInput,
  Forbidden,
  TextInput,
  SelectArrayInput,
  DateField,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { checkSuperadmin } from '@providers';
import { ExportPosition, ordersHttp } from '@network';
import { dateFormatter, exportToCsv, moneyFormatter } from '@utils';
import { Order, OrderHistory } from '@types';
import { OrderPositions } from '@pages/order/OrderPositions';
import { UserStatusField } from '@pages/user/UserStatusField';
import { OrderStatusField, orderStatusOptions } from '../OrderStatusField';
import { OrderDeliveryField, orderDeliveryOptions } from '../OrderDeliveryField';
import cls from './OrderList.module.css';

const ExpandPanel = ({ record }: {record?: Order}) => {
  return !record?.isSummary ? <OrderPositions data={record as Order} /> : null;
};

export const OrderList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Orders list',
    props: {
      'code': {
        label: 'Code',
      },
      'status': {
        label: 'Status',
        prop: 'statusEn',
      },
      'userId': {
        label: 'User',
        prop: 'user.fullName',
      },
      'user.status': {
        label: 'User status',
        prop: 'user.statusEn',
      },
      'user.phone': {
        label: 'User phone',
        prop: 'user.phone',
        transform: 'phone',
      },
      'user.email': {
        label: 'User email',
        prop: 'user.email',
      },
      'user.stateId': {
        label: 'User state',
        prop: 'user.state.name',
      },
      'totalCost': {
        label: 'Total amount',
        transform: 'money',
      },
      'creditId': {
        label: 'Credit',
        prop: 'credit.bank_approval',
      },
      'deliveryStatus': {
        label: 'Delivery status',
        prop: 'deliveryStatusEn',
      },
      'credit.bankId': {
        label: 'Credit bank',
        prop: 'credit.bank.nameMx',
      },
      'user.responsibleAdminId': {
        label: 'Credit manager',
        prop: 'user.responsibleAdmin.name',
      },
      'adminId': {
        label: 'Created by admin',
        prop: 'admin.name',
      },
      'history': {
        label: 'History',
        transform: (history: OrderHistory[]) => history
          .map(h => `[${dateFormatter.toDateTime(h.createdAt)}] ${h.title}`)
          .join(' '),
      },
      'isPaid': {
        label: 'Is paid',
        transform: 'boolean',
      },
      'createdAt': {
        label: 'Created',
        transform: 'datetime',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  const tableRowStyle = (record: Order) => ({
    pointerEvents: record.isSummary ? 'none' : 'all',
  });
  const tableRowClick = (record: any): string => !record?.isSummary ? 'edit' : 'show';
  const isData = (record: Order) => !record.isSummary;

  const onExport = async (queryParams: any) => {
    const positions = await ordersHttp.exportPositions(queryParams);
    const mappingFunc = (item: ExportPosition) => ({
      'Product': item.product,
      'Brand': item.brand ?? '',
      'Category': item.category ?? '',
      'Subcategory': item.subcategory ?? '',
      'Purchased Quantity': item.purchasedQuantity,
      'Total expend': moneyFormatter.format(item.totalExpend),
      'Average unit price': moneyFormatter.format(item.averageUnitPrice),
    });
    exportToCsv(positions, 'Orders by Product', mappingFunc);
  };

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      onExport={onExport}
      exporter={exporter}
    >
      <Grid
        rowClick={tableRowClick}
        rowStyle={tableRowStyle}
        expand={<ExpandPanel />}
        expandSingle
        isRowSelectable={isData}
        isRowExpandable={isData}
        className={cls.grid}
        defaultColumns={'code status totalCost status deliveryStatus userId creditId createdAt'.split(' ')}>
        <FunctionField resource="user"
          source="code"
          label="Code"
          sortBy="id"
          render={(record: any) => (
            <Link to={`/order/${record.id}?initial`}>
              <TextField source="code" />
            </Link>
          )} />
        <OrderStatusField source="status" />
        <ReferenceField reference="user" source="userId" label="User">
          <TextField source="fullName" />
        </ReferenceField>
        <UserStatusField source="user.status" label="User status" />
        <PhoneField source="user.phone" label="User phone" />
        <EmailField source="user.email" label="User email" />
        <ReferenceField reference="state" source="user.stateId" label="User state">
          <TextField source="name" />
        </ReferenceField>
        <MoneyField source="totalCost" label="Total amount" />
        <ReferenceField reference="credit" source="creditId" label="Credit">
          <MoneyField source="bank_approval" />
        </ReferenceField>
        <OrderDeliveryField source="deliveryStatus" label="Delivery status" />
        <ReferenceField reference="bank" source="credit.bankId" label="Credit bank">
          <TextField source="nameMx" />
        </ReferenceField>
        {isSuperadmin && (
          <ReferenceField
            reference="admin"
            source="user.responsibleAdminId"
            label="Credit manager"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {isSuperadmin && (
          <ReferenceField
            reference="admin"
            source="adminId"
            label="Created by admin"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <HistoryField source="history" />
        <BooleanField source="isPaid" label="Is paid" />
        <DateField source="createdAt" label="Created" showTime />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput key="q" source="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={orderStatusOptions}
    alwaysOn
    className="MS-field"
    style={{ width: 280 }}
  />,
  <ReferenceInput
    source="userId"
    key="userId"
    filter={{ forFilter: 'true' }}
    reference="user"
    label="User"
    allowEmpty
    perPage={1000}
    sort={{ field: 'fullName', order: 'ASC' }}
  >
    <SelectInput optionText="fullName" />
  </ReferenceInput>,
  <ReferenceInput
    source="credit.bankId"
    key="credit.bankId"
    reference="bank"
    label="Bank"
    allowEmpty
    perPage={1000}
    sort={{ field: 'nameMx', order: 'ASC' }}
  >
    <SelectInput optionText="nameMx" label="Bank" />
  </ReferenceInput>,
  <SelectInput source="deliveryStatus" key="deliveryStatus" choices={orderDeliveryOptions} />,
  <ManyFilter source="products" key="products" reference="product" />,
  <NullableBooleanInput
    source="createdByAdmin"
    key="createdByAdmin"
    label="Created by admin"
    trueLabel="Yes"
    falseLabel="No"
    style={{ minWidth: 150 }}
  />,
  <ReferenceInput
    source="user.responsibleAdminId"
    key="user.responsibleAdminId"
    reference="admin"
    label="ITC"
    allowEmpty
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" label="ITC" />
  </ReferenceInput>,
  <NullableBooleanInput key="isDemo" source="isDemo" label="Show Demo" />,
];
